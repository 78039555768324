@use 'sizing.module';

$sm: sizing.rem(320px);
$md: sizing.rem(640px);
$lg: sizing.rem(768px);
$xl: sizing.rem(1024px);
$xxl: sizing.rem(1280px);

@mixin smUp {
  @media (min-width: $sm) {
    @content;
  }
}

@mixin mdUp {
  @media (min-width: $md) {
    @content;
  }
}

@mixin lgUp {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin xlUp {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin xxlUp {
  @media (min-width: $xxl) {
    @content;
  }
}
