@use '../../theme/colors.module';
@use '../../theme/sizing.module';
@use '../../theme/text.module';
@use '../../theme/breakpoints.module';
@use '../../theme/zindex.module';

$border-radius: sizing.rem(8px);
$input-height: sizing.rem(47px);

.container {
  align-items: center;
  border: 1px solid colors.$grey-300;
  border-radius: $border-radius;
  display: flex;
  font-family: text.$font-inter;

  &.disabled {
    border-color: colors.$grey-300;
    cursor: not-allowed;
  }

  &.withValue {
    border-color: colors.$grey-500;
    color: colors.$grey-700;
  }

  /*
   * The following selector is to style the flags from the country dropdown.
   * If we omit the :global selector, the compiled CSS would have an incorrect class
   * name, since the class name is dynamically generated by the css module compiler.
   * :global specifies that a class should not be scoped to the component in which it is defined.
   * So if this was .container .fi, the compiled CSS would be the obfuscated version of
   * .component__fi which would be something like .component__1a2b3c4d.
   * By using the :global selector, we can select and style the correct, unobfuscated class name
   * of .fi. While this is less-specific, the flag-icons library uses this .fi class name to apply the
   * flag as a background image.
   * You can read more about the :global selector here:
   * https://shorturl.at/fHJSU
   */
  :global .fi {
    border-radius: sizing.rem(2px);
    box-shadow: 0 0 3px rgba(0,0,0,0.25);
  }

  .dropdownContainer {
    display: flex;
    height: $input-height;
    list-style: none;
    margin: 0;
    padding: 0;

    .dropdownContent {
      align-items: center;
      background-color: colors.$white;
      border-radius: $border-radius;
      cursor: pointer;
      font-size: sizing.rem(16px);
      position: relative;
      transition: all 0.2s;

      .selectedCountry {
        align-items: center;
        background-color: inherit;
        border: 0 none;
        border-radius: $border-radius;
        cursor: pointer;
        display: flex;
        font-size: sizing.rem(16px);
        gap: sizing.rem(6px);
        height: $input-height;
        justify-content: space-between;
        padding: sizing.rem(21px) sizing.rem(4px) sizing.rem(20px) sizing.rem(21px);

        &.withValue {
          color: colors.$grey-700;
        }

        .dialCode {
          margin-right: sizing.rem(6px);
        }
      }

      .countriesContainer {
        background-color: inherit;
        border: 1px solid colors.$grey-400;
        border-radius: $border-radius;
        box-shadow: none;
        color: colors.$grey-700;
        display: block;
        left: 0;
        list-style: none;
        margin-top: sizing.rem(24px);
        max-height: sizing.rem(400px);
        opacity: 1;
        -ms-overflow-style: none; // hide scrollbar on IE and Edge
        overflow-y: auto;
        padding: sizing.rem(18px) 0;
        position: absolute;
        scrollbar-width: none; // hide scrollbar on FF
        top: sizing.rem(30px);
        transition: opacity 0.2s;
        visibility: visible;
        width: sizing.rem(318px); // width required so none of the countries wrap at normal resolution
        @include zindex.floating-content;

        &::-webkit-scrollbar {
          display: none; // hide scrollbar on Chrome, Safari, and Opera
        }

        &.hidden {
          display: none;
          opacity: 0;
          visibility: hidden;
        }

        hr {
          color: colors.$grey-400;
          margin: sizing.rem(18px) 0;
        }
      }
    }
  }

  .input {
    background-color: colors.$white;
    border: 0 none;
    border-radius: $border-radius;
    color: colors.$grey-500;
    font-family: text.$font-inter;
    font-size: sizing.rem(16px);
    height: $input-height;
    padding: 0 sizing.rem(21px);
    width: 100%;

    ::placeholder{
      color: colors.$grey-400;
    }

    &.tel {
      margin-left: sizing.rem(-71px);
      padding-left: sizing.rem(71px);
    }

    &.withValue {
      color: colors.$grey-700;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: none; // hide the browser-default outline
    }

    &:disabled {
      &:hover {
        cursor: not-allowed;
      }

      ::placeholder{
        color: colors.$grey-300;
      }
    }
  }
}

@include breakpoints.mdUp {
  .input {
    padding: 0 sizing.rem(14px);
  }
}


