@use 'theme/sizing.module';
@use 'theme/colors.module';
.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
}

.video {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.introContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: sizing.rem(500px);

  .headerWrapper {
    display: flex;
    flex-direction: column;
  }
  .header {
    color: colors.$white;
    font-size: sizing.rem(72px);
    font-weight: 600;
  }

  .subHeader {
    color: colors.$danger-400;
    font-size: sizing.rem(32px);
    font-weight: 600;
  }

  .footnote {
    color: colors.$white;
    justify-self: flex-end;
    font-size: sizing.rem(20px);
  }
}

.backgroundImage {
  z-index: -1;
  width: 100vw;
  height: sizing.rem(640px);
}

.workFlowWrapper {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: sizing.rem(80px) sizing.rem(100px);
  justify-content: space-between;

  .flowGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
    border: 1px solid colors.$grey-200;
    border-radius: sizing.rem(10px);
    height: sizing.rem(400px);
    padding: sizing.rem(40px) sizing.rem(60px);
    margin: 0 sizing.rem(20px);
    -webkit-box-shadow: -5px 5px 8px 3px rgba(0,0,0,0.25);
    box-shadow: -5px 5px 8px 3px rgba(0,0,0,0.25);

    .flowHeader {
      font-weight: 600;
      font-size: sizing.rem(30px);
      color: colors.$grey-600;
      margin-top: sizing.rem(20px);
    }

    .horizontalDivider {
      border-top: colors.$danger-500 solid 2px;
      width: sizing.rem(60px);
      margin: sizing.rem(25px) 0;
    }

    .icon {
      width: sizing.rem(100px);
      height: sizing.rem(100px);
      color: colors.$danger-500;
    }

    .flowText {
      font-weight: 500;
      font-size: sizing.rem(24px);
      color: colors.$grey-600;
      line-height: sizing.rem(30px);
      margin-bottom: sizing.rem(30px);
    }
  }
}

.logo {
  height: sizing.rem(300px);
  width: sizing.rem(700px);
}

.button {
  background-color: white;
  padding: sizing.rem(10px) sizing.rem(20px);
  width: sizing.rem(300px);
  border: none;
  border-radius: sizing.rem(5px);
  font-size: sizing.rem(16px);
  opacity: 0.9;
}
