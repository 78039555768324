$transparent: transparent;
$white: #ffffff;
$black: #000000;

$grey-900: #1b1b1b;
$grey-800: #181a1b;
$grey-700: #3c3e3f;
$grey-600: #525454;
$grey-500: #696969;
$grey-400: #838687;
$grey-300: #b3b3b3;
$grey-200: #d1d1d1;
$grey-100: #e4e4e4;
$grey-50: #edefef;
$grey-25: #f7f9f9;

$accent-900: #370015;
$accent-800: #582538;
$accent-700: #86113a;
$accent-600: #a91e46;
$accent-500: #ce355d;
$accent-400: #d91b5c;
$accent-300: #df5e8b;
$accent-200: #fea9b7;
$accent-100: #fed4d9;
$accent-50: #fff7f6;
$accent-25: #fffaff;

$danger-900: #67090c;
$danger-800: #67090c;
$danger-700: #870011;
$danger-600: #b0171c;
$danger-500: #d82f2e;
$danger-400: #fd4943;
$danger-300: #f2938c;
$danger-200: #feaaa2;
$danger-100: #ffd5d1;
$danger-50: #ffeae7;
$danger-25: #fff7f6;

$warning-900: #663000;
$warning-800: #93370d;
$warning-700: #b54708;
$warning-600: #dc6803;
$warning-500: #f79009;
$warning-400: #fdb022;
$warning-300: #fec84b;
$warning-200: #fedf89;
$warning-100: #fef0c7;
$warning-50: #fffaeb;
$warning-25: #fffcf5;

$success-900: #0f5a45;
$success-800: #05603a;
$success-700: #027a48;
$success-600: #1a9672;
$success-500: #1ea981;
$success-400: #4ebb9c;
$success-300: #69bba3;
$success-200: #8cd1bd;
$success-100: #d2eee6;
$success-50: #ecfdf3;
$success-25: #f6fef9;

$blue-700: #00629f;
$blue-600: #0075bf;
$blue-500: #3b93ca;
$blue-400: #75b0d5;
